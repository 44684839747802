import { useCognitoNewPassword } from '@openeducation/hr-rn-cognito-providers';
import { getErrorTranslation } from '@openeducation/hr-rn-cognito-services';
import { HRNewPasswordPage } from '@openeducation/hr-rn-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useLocation } from '../router';

interface LocationState {
  state: {
    email: string;
  };
}

const NewPasswordScreen = () => {
  const location = useLocation() as LocationState;
  const { completeNewPassword, newPasswordError, isNewPasswordLoading } =
    useCognitoNewPassword();
  const { t } = useTranslation();

  const email = location?.state?.email;

  return (
    <HRNewPasswordPage
      email={email}
      onSubmit={completeNewPassword}
      isLoading={isNewPasswordLoading}
      error={newPasswordError ? t(getErrorTranslation(newPasswordError)) : ''}
    />
  );
};

export default NewPasswordScreen;
