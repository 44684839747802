import {
  useCognitoAuth,
  useAdminModeAuth,
} from '@openeducation/hr-rn-cognito-providers';
import {
  useHRPageTemplateProps,
  useOnLogout,
  useOmLanguages,
  CenteredLoading,
  flex,
  getLanguageByLocalization,
  useLogoutWhenIdle,
} from '@openeducation/hr-rn-components';
import { useHRGetLocalLanguage } from '@openeducation/hr-rn-providers';
import * as Localization from 'expo-localization';
import React from 'react';

import { PageTemplate } from './page-template';
import { RouteType } from './private-routes';
import { publicRoutes } from './public-routes';
import { Route, Routes, useLocation } from './router';
import {
  useNavItems,
  useResolveInitialRoute,
  useSetHRPortalConfig,
  usePrivateRoutes,
} from '../hooks';

const Loader = () => <CenteredLoading size={100} style={[flex('flex-1')]} />;

const AppPublicRoutes = () => {
  const locale = Localization.locale;
  const { isLocalLanguageLoading } = useHRGetLocalLanguage(
    getLanguageByLocalization,
    locale
  );

  if (isLocalLanguageLoading) {
    return <Loader />;
  }

  return (
    <Routes>
      {publicRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={route.component} />
      ))}
    </Routes>
  );
};

const AppPrivateRoutes = () => {
  const navItems = useNavItems();
  const pageTemplateProps = useHRPageTemplateProps();
  const { routes, showFullPageLoader } = usePrivateRoutes();

  useOmLanguages();

  if (showFullPageLoader) {
    return <Loader />;
  }

  return (
    <PageTemplate {...pageTemplateProps} navItems={navItems}>
      <Routes>
        {routes.map((route: RouteType) => (
          <Route key={route.path} path={route.path} element={route.component} />
        ))}
      </Routes>
    </PageTemplate>
  );
};

const AppRoutes = () => {
  const { pathname } = useLocation();
  const checkAdmin = pathname === '/login' || pathname === '/';
  useAdminModeAuth(checkAdmin);

  const { isLoggedIn, logout } = useCognitoAuth(true);
  useResolveInitialRoute(isLoggedIn);
  useLogoutWhenIdle(isLoggedIn, logout);
  useSetHRPortalConfig();
  useOnLogout(isLoggedIn);

  if (isLoggedIn === null) {
    return <Loader />;
  }

  if (!isLoggedIn) {
    return <AppPublicRoutes />;
  }

  return <AppPrivateRoutes />;
};

export default AppRoutes;
