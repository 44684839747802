import { getUrlParams } from '@openeducation/hr-js-utils';
import { HRResetPasswordLinkPage } from '@openeducation/hr-rn-components';
import { useResetPasswordConfirm } from '@openeducation/hr-rn-providers';
import React, { useEffect } from 'react';

import { Path, useNavigate } from '../router';

const ResetPasswordLinkScreen = () => {
  const { email, token } = getUrlParams();
  const navigate = useNavigate();

  const onRecoverPassword = () => {
    navigate(Path.ResetPassword);
  };
  const {
    confirm,
    isResetPasswordConfirmError,
    isResetPasswordConfirmLoading,
  } = useResetPasswordConfirm();

  useEffect(() => {
    if (email && token) {
      confirm({ email, token });
    }
  }, [confirm, email, token]);

  return (
    <HRResetPasswordLinkPage
      hasError={isResetPasswordConfirmError}
      isLoading={isResetPasswordConfirmLoading}
      onRecoverPassword={onRecoverPassword}
      email={email}
    />
  );
};

export default ResetPasswordLinkScreen;
