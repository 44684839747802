import { usePurchaserId } from '@openeducation/hr-rn-cognito-providers';
import {
  HRMonthlyAchievements,
  HRHeading,
  HRActivitiesOverview,
  HRMostActiveStudents,
  HRLeastActiveStudents,
  useHRPortalTranslation,
  useMonthlyAchievements,
  useLeastActiveStudents,
  useOEMostActiveStudents,
  useByDateReport,
  useReport,
  SectionPlaceholder,
  flex,
  mb,
  HRStudentsHorizontalBarChart,
  OMStatisticsOfActivities,
  saveFile,
} from '@openeducation/hr-rn-components';
import {
  hrManagerAtom,
  useGetOMActivityIntervals,
  useGetOMAllActivitiesOverview,
  useGetOMLeastActiveStudents,
} from '@openeducation/hr-rn-graphql-providers';
import { omLanguageSelected } from '@openeducation/hr-rn-jotai-atoms';
import { ActivityType, ReportType } from '@openeducation/hr-rn-shared-types';
import { useAtomValue } from 'jotai';
import React, { useState } from 'react';
import { View } from 'react-native';

import { useStudentsListProps } from '../../hooks/use-students-list-props';
import { OMPath } from '../../router';

const OMHomeScreen = () => {
  const [inactiveDaysThreshold, setInactiveDaysThreshold] =
    useState<number>(30);

  const hrManager = useAtomValue(hrManagerAtom);
  const { langCode: languageCode = undefined } =
    useAtomValue(omLanguageSelected) || {};
  const { t } = useHRPortalTranslation();
  const purchaserId = usePurchaserId();

  const {
    loading: activitiesOverviewLoading,
    data: activitiesOverviewData,
    isError: activitiesOverviewError,
  } = useGetOMAllActivitiesOverview({
    purchaserId,
    languageCode,
  });

  const { mostActiveStudentsChartData, ...mostActiveStudentsProps } =
    useOEMostActiveStudents();

  useGetOMActivityIntervals({
    purchaserId,
    languageCode,
  });

  const monthlyAchievementsProps = useMonthlyAchievements();

  const leastActiveStudentsData15 = useGetOMLeastActiveStudents({
    purchaserId,
    inactiveDaysThreshold: 15,
    languageCode,
  });
  const leastActiveStudentsData30 = useGetOMLeastActiveStudents({
    purchaserId,
    inactiveDaysThreshold: 30,
    languageCode,
  });

  const leastActiveStudentsData =
    inactiveDaysThreshold === 15
      ? leastActiveStudentsData15
      : leastActiveStudentsData30;

  const leastActiveStudentsProps = useLeastActiveStudents(
    leastActiveStudentsData
  );
  const hasLeastActiveStudents =
    (leastActiveStudentsData30?.data || []).length > 0 ||
    (leastActiveStudentsData15?.data || []).length > 0;

  const studentsListProps = useStudentsListProps(OMPath.Student);
  const historicalReport = useReport(saveFile, ReportType.HISTORICAL);
  const periodReport = useByDateReport(saveFile, ReportType.ACTIVITY_BY_PERIOD);

  return (
    <>
      <HRHeading
        title={t('home.heading.title', { managerName: hrManager?.name })}
        description={t('home.heading.description')}
        studentsListProps={studentsListProps}
        historicalReport={historicalReport}
        periodReport={periodReport}
        changeLanguageSelected
      />
      {!activitiesOverviewError && (
        <HRActivitiesOverview
          isLoading={activitiesOverviewLoading}
          stats={activitiesOverviewData}
          subtitle={t('home.activitiesOverview.subtitle')}
          isOM
        />
      )}
      <View style={[flex('flex-row', 'flex-1')]}>
        <OMStatisticsOfActivities languageCode={languageCode} />
      </View>
      <HRMonthlyAchievements<ActivityType> {...monthlyAchievementsProps} />
      <HRMostActiveStudents {...mostActiveStudentsProps}>
        <View style={[flex('flex-1'), mb(15)]}>
          {mostActiveStudentsChartData.values.length === 0 ? (
            <SectionPlaceholder text={t('common.chart.noData')} />
          ) : (
            <HRStudentsHorizontalBarChart {...mostActiveStudentsChartData} />
          )}
        </View>
      </HRMostActiveStudents>
      {hasLeastActiveStudents && (
        <HRLeastActiveStudents
          {...leastActiveStudentsProps}
          title={t('leastActiveStudents.sectionTitle', {
            count: inactiveDaysThreshold,
          })}
          onInactiveDaysChanges={setInactiveDaysThreshold}
        />
      )}
    </>
  );
};

export default OMHomeScreen;
