import { useConfig } from '@openeducation/hr-rn-providers';
import { initializeAnalytics } from '@openeducation/hr-rn-services';
import { Provider as AtomProvider } from 'jotai';
import React, { useEffect } from 'react';

import { useLoadAmplifyConfig } from './src/hooks/use-load-amplify-config';
import { AppProviders } from './src/providers/app-providers';
import AppRoutes from './src/router/app-routing';
import { Router } from './src/router/router';

export default function App() {
  const { config } = useConfig();
  const { isAmplifyConfigLoaded } = useLoadAmplifyConfig(config?.environment);
  const isProd = config?.environment === 'prod';
  useEffect(() => {
    if (isProd) {
      initializeAnalytics({
        googleAnalyticsTrackingId: 'G-NB12KPBGEK',
        hotjarTrackingId: [3296984, 6],
      });
    }
  }, [isProd]);

  if (!isAmplifyConfigLoaded || !config) {
    return null;
  }

  return (
    <AtomProvider>
      <AppProviders env={config.environment}>
        <Router>
          <AppRoutes />
        </Router>
      </AppProviders>
    </AtomProvider>
  );
}
