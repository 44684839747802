import { Organization } from "@openeducation/hr-rn-shared-types";
import { useConfigurations } from "@openeducation/hr-rn-components";
import { useMemo } from "react";

import { Path, PathType, pathPrefixByOrganization } from "../router/paths";
import { privateRoutes, RouteType } from "../router/private-routes";

const commonPaths = Object.values(Path);

const commonPrivateRoutes = privateRoutes.filter((route: RouteType) =>
  commonPaths.includes(route.path as PathType)
);

export const usePrivateRoutes = () => {
  const { organizations, configurationsLoading } = useConfigurations();

  const routes = useMemo(() => {
    if (configurationsLoading) return commonPrivateRoutes;

    const organizationRoutes =
      organizations
        .map((organization: Organization) =>
          privateRoutes.filter((route: RouteType) =>
            route.path.includes(pathPrefixByOrganization[organization.type])
          )
        )
        .flat() || [];
    return [...commonPrivateRoutes, ...organizationRoutes];
  }, [organizations, configurationsLoading]);

  return { routes, showFullPageLoader: configurationsLoading };
};
