import { HRResetPasswordEmailPage } from '@openeducation/hr-rn-components';
import React from 'react';

import { useLocation } from '../router';

interface LocationState {
  state: {
    email: string;
  };
}

const ResetPasswordEmailScreen = () => {
  const location = useLocation() as LocationState;
  const email = location?.state?.email;
  return <HRResetPasswordEmailPage email={email} />;
};

export default ResetPasswordEmailScreen;
