import {
  HRChoicePage,
  useConfigurations,
} from '@openeducation/hr-rn-components';
import { useConfig } from '@openeducation/hr-rn-providers';
import { OrganizationType } from '@openeducation/hr-rn-shared-types';
import React from 'react';

import { useNavigate, OEPath, OMPath } from '../router';

const ChoiceScreen = () => {
  const navigate = useNavigate();

  const { organizations, configurationsLoading } = useConfigurations();
  const { config } = useConfig();

  const onProductSelect = (organization: OrganizationType) => {
    switch (organization) {
      case OrganizationType.OPEN_MUNDO: {
        navigate(OMPath.Home);
        break;
      }
      case OrganizationType.OPEN_ENGLISH:
      default:
        navigate(OEPath.Home);
    }
  };

  if (configurationsLoading || !config?.environment) {
    return null;
  }

  return (
    <HRChoicePage
      organizations={organizations || []}
      onProductSelect={onProductSelect}
    />
  );
};

export default ChoiceScreen;
