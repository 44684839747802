import React from 'react';

import { Path, OEPath, OMPath } from './paths';
import { Navigate } from './router';
import AdminScreen from '../screens/admin.screen';
import ChoiceScreen from '../screens/choice.screen';
import OEHomeScreen from '../screens/open-english/oe-home.screen';
import OELicensesScreen from '../screens/open-english/oe-licenses.screen';
import OEStudentScreen from '../screens/open-english/oe-student.screen';
import OMHomeScreen from '../screens/open-mundo/om-home.screen';
import OMLicensesScreen from '../screens/open-mundo/om-licenses.screen';
import OMStudentScreen from '../screens/open-mundo/om-student.screen';

export type RouteType = {
  path: Path | OEPath | OMPath;
  private: boolean;
  component: JSX.Element;
};

export const privateRoutes = [
  {
    path: Path.Root,
    private: true,
    component: <Navigate replace to={Path.Choice} />,
  },
  {
    path: Path.Choice,
    private: true,
    component: <ChoiceScreen />,
  },

  // Open English paths
  {
    path: OEPath.Home,
    private: true,
    component: <OEHomeScreen />,
  },
  {
    path: OEPath.Licenses,
    private: true,
    component: <OELicensesScreen />,
  },
  {
    path: OEPath.Student,
    private: true,
    component: <OEStudentScreen />,
  },
  {
    path: OEPath.Admin,
    private: true,
    component: <AdminScreen />,
  },

  // OpenMundo paths
  {
    path: OMPath.Home,
    private: true,
    component: <OMHomeScreen />,
  },
  {
    path: OMPath.Licenses,
    private: true,
    component: <OMLicensesScreen />,
  },
  {
    path: OMPath.Student,
    private: true,
    component: <OMStudentScreen />,
  },
  {
    path: OMPath.Admin,
    private: true,
    component: <AdminScreen />,
  },
];
