import {
  HRHeadingProps,
  useAllStudentsList,
} from '@openeducation/hr-rn-components';
import { Person } from '@openeducation/hr-rn-shared-types';

import { OMPath, OEPath, useNavigate } from '../router';

export const useStudentsListProps = (
  pathname: OEPath | OMPath
): HRHeadingProps['studentsListProps'] => {
  const { data } = useAllStudentsList();
  const navigate = useNavigate();

  const onStudentSelect = (contactId: Person['contactId']) => {
    navigate({
      pathname,
      search: `?id=${contactId}`,
    });
  };

  return {
    data,
    onStudentSelect,
  };
};
