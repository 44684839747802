import { usePurchaserId } from '@openeducation/hr-rn-cognito-providers';
import {
  HRHeading,
  HRLicensesOverview,
  HRLicensesList,
  HRContactUsModal,
  HRChangeStudyPlanModal,
  HRAssignReassignLicenseModal,
  useHRPortalTranslation,
  useLicensesList,
  useAssignReassignLicense,
  useContactUs,
  useReport,
  useCountrySelector,
  useStudyPlans,
  useChangeStudyPlan,
  useUndoStudyPlan,
  SloganBanner,
  mb,
  position,
  useMassiveAssignment,
  HRMassiveAssignmentModal,
  HRMassiveAssignmentModalProcess,
  HRModal,
  saveFile,
} from '@openeducation/hr-rn-components';
import { useGetLicensesOverview } from '@openeducation/hr-rn-graphql-providers';
import {
  hrPortalConfigAtom,
  studyPlanEnabledAtom,
} from '@openeducation/hr-rn-jotai-atoms';
import {
  StudentsListField,
  ReportType,
  StudentsListItemData,
} from '@openeducation/hr-rn-shared-types';
import { useAtomValue } from 'jotai';
import React, { useState, useEffect, useCallback } from 'react';
import { View } from 'react-native';

import { useStudentsListProps } from '../../hooks/use-students-list-props';
import { OEPath } from '../../router';

enum VisibleModal {
  CONTACT_US = 'CONTACT_US',
  CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS',
}

const OELicensesScreen = () => {
  const [visibleModal, setVisibleModal] = useState<VisibleModal | null>(null);
  const { t } = useHRPortalTranslation();
  const purchaserId = usePurchaserId();
  const { moreLicensesEmailSubject, organization } =
    useAtomValue(hrPortalConfigAtom);

  const studyPlanEnable = useAtomValue(studyPlanEnabledAtom);
  const { data: overviewData, loading: overviewLoading } =
    useGetLicensesOverview({
      purchaserId,
      organization,
    });
  const contactUsProps = useContactUs({
    subject: moreLicensesEmailSubject,
  });
  const {
    data: listData,
    isLoading: listLoading,
    refetchListData,
    lastUpdate: licenseListLastUpdate,
  } = useLicensesList();
  const studyPlansProps = useStudyPlans(refetchListData);
  const {
    massiveAssignmentModal,
    massiveAssignmentForm,
    massiveAssignmentList,
    massiveAssignmentProcess,
    massiveAssignmentFile,
  } = useMassiveAssignment({
    licensesData: listData,
    studyPlans: studyPlansProps.studyPlansData,
    refetch: refetchListData,
  });

  const studentsListProps = useStudentsListProps(OEPath.Student);
  const licensesReport = useReport(saveFile, ReportType.LICENSES);
  const countrySelectorProps = useCountrySelector();

  const unassignStudyPlanProps = useUndoStudyPlan(refetchListData);
  const { openChangeStudyPlan, modalChangeStudyPlanProps } = useChangeStudyPlan(
    studyPlansProps,
    unassignStudyPlanProps
  );
  const { openAssignReassign, modalAssignReassignLicenseProps } =
    useAssignReassignLicense(studyPlansProps, refetchListData);

  useEffect(() => {
    if (contactUsProps?.isSuccess) {
      setVisibleModal(VisibleModal.CONTACT_US_SUCCESS);
    }
  }, [contactUsProps?.isSuccess]);

  const closeModals = () => setVisibleModal(null);

  const openContactModal = () => {
    setVisibleModal(VisibleModal.CONTACT_US);
  };

  const onChangeStudyPlan = useCallback(
    (license: StudentsListItemData) => {
      openChangeStudyPlan(license);
    },
    [openChangeStudyPlan]
  );

  const onAssignReassign = useCallback(
    (license: StudentsListItemData) => {
      openAssignReassign(license);
    },
    [openAssignReassign]
  );
  return (
    <>
      <HRHeading
        title={t('licenses.heading.title')}
        description={t('licenses.heading.description')}
        studentsListProps={studentsListProps}
        licensesReport={licensesReport}
      />
      <HRLicensesOverview
        isLoading={overviewLoading}
        data={overviewData}
        onMoreLicensesPress={openContactModal}
      />
      <HRLicensesList
        data={listData}
        isLoading={listLoading}
        refetchListData={refetchListData}
        onChangeStudyPlan={onChangeStudyPlan}
        onAssignReassign={onAssignReassign}
        fields={[
          StudentsListField.NAME,
          StudentsListField.EMAIL,
          StudentsListField.PRIVATE_CLASSES,
          StudentsListField.STATUS,
          StudentsListField.EXPIRATION,
          studyPlanEnable
            ? StudentsListField.SETTINGS
            : StudentsListField.ASSIGN_REASSIGN,
        ]}
        enableMassiveAssignment
        style={position('relative')}
        massiveAssignmentList={massiveAssignmentList}
        onSelectedChange={massiveAssignmentList?.onSelectedChange}
        selecteId="licenseId"
        selectitonAllType="all"
        licenseListLastUpdate={licenseListLastUpdate}
      />
      <HRAssignReassignLicenseModal
        {...modalAssignReassignLicenseProps}
        countrySelectorProps={countrySelectorProps}
      />

      {studyPlanEnable && (
        <HRChangeStudyPlanModal {...modalChangeStudyPlanProps} />
      )}
      <View style={[mb(40)]}>
        <SloganBanner
          text={t('licenses.slogan')}
          button={{
            onPress: openContactModal,
            text: t('licenses.overview.moreLicenses'),
          }}
        />
      </View>
      <HRContactUsModal
        title={t('getLicenses.title')}
        description={t('getLicenses.description')}
        visible={visibleModal === VisibleModal.CONTACT_US}
        onCancel={closeModals}
        isLoading={contactUsProps?.isLoading}
        error={contactUsProps?.error}
        onSubmit={contactUsProps?.onSubmit}
        managerName={contactUsProps?.managerName}
        managerEmail={contactUsProps?.managerEmail}
      />
      <HRModal
        visible={visibleModal === VisibleModal.CONTACT_US_SUCCESS}
        onClose={closeModals}
        successMessage={{ message: t('getLicenses.success.title') }}
        successMessageSecondary={{
          message: t('getLicenses.success.description'),
        }}
        autoDismiss
      />
      <HRMassiveAssignmentModal
        massiveAssignmentModal={massiveAssignmentModal}
        massiveAssignmentForm={massiveAssignmentForm}
        massiveAssignmentFile={massiveAssignmentFile}
        studyPlans={studyPlansProps.studyPlansData}
      />
      <HRMassiveAssignmentModalProcess
        massiveAssignmentProcess={massiveAssignmentProcess}
      />
    </>
  );
};

export default OELicensesScreen;
