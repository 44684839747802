import { useCognitoLogin } from '@openeducation/hr-rn-cognito-providers';
import { getErrorTranslation } from '@openeducation/hr-rn-cognito-services';
import { HRLoginPage } from '@openeducation/hr-rn-components';
import { getUrlParams } from '@openeducation/hr-js-utils';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Path, useNavigate } from '../router';

const LoginScreen = () => {
  const navigate = useNavigate();
  const setOnNewPasswordRequired = (email: string) => {
    navigate(Path.NewPassword, { state: { email } });
  };
  const { loginWithEmailAndPassword, loginError, loginLoading } =
    useCognitoLogin(setOnNewPasswordRequired);
  const { firstLogin } = getUrlParams();
  const { t } = useTranslation();

  const labels = useMemo(() => {
    return {
      emailLabel: t('hr2.common.email'),
      passwordLabel: t(
        firstLogin ? 'hr2.auth.defaultPassword' : 'hr2.common.password'
      ),
      passwordLink: firstLogin ? undefined : t('hr2.auth.login.forgotPassword'),
      buttonSubmit: t('hr2.auth.login.buttonSubmit'),
    };
  }, [firstLogin, t]);

  const onRecoverPassword = () => {
    navigate(Path.ResetPassword);
  };

  return (
    <HRLoginPage
      onRecoverPassword={onRecoverPassword}
      labels={labels}
      onSubmit={loginWithEmailAndPassword}
      isLoading={loginLoading}
      error={loginError ? t(getErrorTranslation(loginError)) : ''}
    />
  );
};

export default LoginScreen;
