import {
  usePurchaserId,
  useIsAdmin,
} from '@openeducation/hr-rn-cognito-providers';
import {
  HRMonthlyAchievements,
  HRHeading,
  HRActivitiesOverview,
  HRStudentsByLevel,
  HRMostActiveStudents,
  HRLeastActiveStudents,
  HRStudyPlanHome,
  useHRPortalTranslation,
  useMonthlyAchievements,
  useLeastActiveStudents,
  useOEMostActiveStudents,
  useByDateReport,
  useReport,
  useStudentsByLevel,
  useStudyPlans,
  useHomeStudyPlanStatistics,
  SectionPlaceholder,
  flex,
  mb,
  useGroupClassAttendance,
  HRGroupClassAttendanceModal,
  HRActivitiesPieChart,
  OEStatisticsOfActivities,
  HRStudentsHorizontalBarChart,
  saveFile,
} from '@openeducation/hr-rn-components';
import {
  hrManagerAtom,
  useGetActivityIntervals,
  useGetStudyPlanIntervals,
  useGetAllActivitiesOverview,
  useGetLeastActiveStudents,
} from '@openeducation/hr-rn-graphql-providers';
import { studyPlanEnabledAtom } from '@openeducation/hr-rn-jotai-atoms';
import { ActivityType, ReportType } from '@openeducation/hr-rn-shared-types';
import { useAtomValue } from 'jotai';
import React, { useCallback, useState } from 'react';
import { View } from 'react-native';

import { useStudentsListProps } from '../../hooks/use-students-list-props';
import { OEPath, useNavigate } from '../../router';

const OEHomeScreen = () => {
  const isAdmin = useIsAdmin();
  const [inactiveDaysThreshold, setInactiveDaysThreshold] =
    useState<number>(30);

  const navigate = useNavigate();
  const { studyPlansData } = useStudyPlans();
  const hrManager = useAtomValue(hrManagerAtom);
  const studyPlanEnable = useAtomValue(studyPlanEnabledAtom);
  const { t } = useHRPortalTranslation();
  const purchaserId = usePurchaserId();

  const {
    loading: activitiesOverviewLoading,
    data: activitiesOverviewData,
    isError: activitiesOverviewError,
  } = useGetAllActivitiesOverview({ purchaserId });
  const {
    studentsByLevelValues,
    studentsByLevelColors,
    studentByLevelLabels,
    studentsByLevelLoading,
  } = useStudentsByLevel();
  const { mostActiveStudentsChartData, ...mostActiveStudentsProps } =
    useOEMostActiveStudents();
  useGetActivityIntervals({ purchaserId });

  const { studyPlanIntervalDates } = useGetStudyPlanIntervals({
    purchaserId,
  });

  const monthlyAchievementsProps = useMonthlyAchievements();
  const leastActiveStudentsData15 = useGetLeastActiveStudents({
    purchaserId,
    inactiveDaysThreshold: 15,
  });
  const leastActiveStudentsData30 = useGetLeastActiveStudents({
    purchaserId,
    inactiveDaysThreshold: 30,
  });
  const leastActiveStudentsData =
    inactiveDaysThreshold === 15
      ? leastActiveStudentsData15
      : leastActiveStudentsData30;

  const leastActiveStudentsProps = useLeastActiveStudents(
    leastActiveStudentsData
  );

  const hasLeastActiveStudents =
    (leastActiveStudentsData30?.data || []).length > 0 ||
    (leastActiveStudentsData15?.data || []).length > 0;

  const studentsListProps = useStudentsListProps(OEPath.Student);
  const historicalReport = useReport(saveFile, ReportType.HISTORICAL);
  const periodReport = useByDateReport(saveFile, ReportType.ACTIVITY_BY_PERIOD);

  const studyPlanReport = useReport(saveFile, ReportType.STUDY_PLAN);
  const onStudentSelect = (contactId: string) => {
    navigate({
      pathname: OEPath.Student,
      search: `?id=${contactId}`,
    });
  };
  const homeStatisticsProps = useHomeStudyPlanStatistics({
    studyPlansData,
    purchaserId,
    studyPlanIntervalDates,
    onStudentSelect,
    report: studyPlanReport,
  });

  const {
    isGroupClassAttendanceEnabled,
    openGroupClassAttendance,
    closeGroupClassAttendance,
    groupClassAttendanceVisible,
    selectedGroup,
    selectedStudents,
    selectedDate,
    ...groupClassAttendance
  } = useGroupClassAttendance();

  const renderStudentsByLevel = useCallback(() => {
    if (!studentsByLevelLoading && studentsByLevelValues.length === 0) {
      return null;
    }

    const commonProps = {
      colors: studentsByLevelColors,
      labels: studentByLevelLabels,
      values: studentsByLevelValues,
    };

    return (
      <HRStudentsByLevel isLoading={studentsByLevelLoading} {...commonProps}>
        <HRActivitiesPieChart {...commonProps} />
      </HRStudentsByLevel>
    );
  }, [
    studentsByLevelLoading,
    studentsByLevelValues,
    studentsByLevelColors,
    studentByLevelLabels,
  ]);

  return (
    <>
      <HRHeading
        title={t('home.heading.title', { managerName: hrManager?.name })}
        description={t('home.heading.description')}
        studentsListProps={studentsListProps}
        historicalReport={historicalReport}
        periodReport={periodReport}
      />
      {!activitiesOverviewError && (
        <HRActivitiesOverview
          isLoading={activitiesOverviewLoading}
          stats={activitiesOverviewData}
          subtitle={t('home.activitiesOverview.subtitle')}
        />
      )}
      {studyPlanEnable && <HRStudyPlanHome {...homeStatisticsProps} />}
      <View style={[flex('flex-row', 'flex-1')]}>
        <OEStatisticsOfActivities
          isGroupClassAttendanceEnabled={isGroupClassAttendanceEnabled}
          onGroupClassAttendancePress={openGroupClassAttendance}
        />
        {renderStudentsByLevel()}
      </View>

      <HRMonthlyAchievements<ActivityType> {...monthlyAchievementsProps} />
      <HRMostActiveStudents {...mostActiveStudentsProps}>
        <View style={[flex('flex-1'), mb(15)]}>
          {mostActiveStudentsChartData.values.length === 0 ? (
            <SectionPlaceholder text={t('common.chart.noData')} />
          ) : (
            <HRStudentsHorizontalBarChart {...mostActiveStudentsChartData} />
          )}
        </View>
      </HRMostActiveStudents>
      {hasLeastActiveStudents && (
        <HRLeastActiveStudents
          {...leastActiveStudentsProps}
          title={t('leastActiveStudents.sectionTitle', {
            count: inactiveDaysThreshold,
          })}
          onInactiveDaysChanges={setInactiveDaysThreshold}
          disableSubmit={isAdmin}
        />
      )}
      {isGroupClassAttendanceEnabled && (
        <HRGroupClassAttendanceModal
          step={groupClassAttendance.step}
          groups={groupClassAttendance.groups}
          onGroupSelect={groupClassAttendance.onGroupSelect}
          onStudentsSelect={groupClassAttendance.onStudentsSelect}
          students={groupClassAttendance.students}
          onClose={closeGroupClassAttendance}
          visible={groupClassAttendanceVisible}
          onGoBack={groupClassAttendance.onGoBack}
          confirm={groupClassAttendance.confirm}
          onDateChange={groupClassAttendance.onDateChange}
          selectedGroup={selectedGroup}
          selectedStudents={selectedStudents}
          selectedDate={selectedDate}
          onSubmit={groupClassAttendance.onAttendanceSubmit}
          isLoading={groupClassAttendance.addLiveClassProgressLoading}
          success={groupClassAttendance.addLiveClassProgressSuccess}
          error={groupClassAttendance.addLiveClassProgressError}
          stepLabel={groupClassAttendance.stepLabel}
        />
      )}
    </>
  );
};

export default OEHomeScreen;
