import React from 'react';

import { Path } from './paths';
import LoginScreen from '../screens/login.screen';
import NewPasswordScreen from '../screens/new-password.screen';
import ResetPasswordEmailScreen from '../screens/reset-password-email.screen';
import ResetPasswordLinkScreen from '../screens/reset-password-link.screen';
import ResetPasswordScreen from '../screens/reset-password.screen';

export const publicRoutes = [
  {
    path: Path.Login,
    private: false,
    component: <LoginScreen />,
  },
  {
    path: Path.NewPassword,
    private: false,
    component: <NewPasswordScreen />,
  },
  {
    path: Path.ResetPassword,
    private: false,
    component: <ResetPasswordScreen />,
  },
  {
    path: Path.ResetPasswordEmail,
    private: false,
    component: <ResetPasswordEmailScreen />,
  },
  {
    path: Path.ResetPasswordLink,
    private: false,
    component: <ResetPasswordLinkScreen />,
  },
];
