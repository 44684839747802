import { OrganizationType } from '@openeducation/hr-rn-shared-types';

export enum Path {
  Root = '/',
  Login = '/login',
  NewPassword = '/new-password',
  ResetPassword = '/reset-password',
  ResetPasswordEmail = '/reset-password-email',
  ResetPasswordLink = '/reset-password-link',
  Choice = '/choice',
}

export enum OEPath {
  Home = '/oe-home',
  Licenses = '/oe-licenses',
  Student = '/oe-student',
  Admin = '/oe-admin',
}

export enum OMPath {
  Home = '/om-home',
  Licenses = '/om-licenses',
  Student = '/om-student',
  Admin = '/om-admin',
}

export type PathType = Path & OEPath & OMPath;

export const pathPrefixByOrganization = {
  [OrganizationType.OPEN_ENGLISH]: 'oe-',
  [OrganizationType.OPEN_MUNDO]: 'om-',
};
