import { Environment } from "@openeducation/hr-rn-shared-types";
import { Amplify } from "aws-amplify";
import React from "react";

const stage = require(`../../aws-config.stg.json`);
const prod = require(`../../aws-config.prod.json`);

export const useLoadAmplifyConfig = (environment?: Environment) => {
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    if (environment) {
      const json = environment === "prod" ? prod : stage;
      if (json) {
        Amplify.configure(json);
        setIsLoaded(true);
      } else {
        // TODO handle error, maybe redirect to a default error page?
      }
    }
  }, [environment]);

  return {
    isAmplifyConfigLoaded: isLoaded,
  };
};
