import { getUrlParams } from '@openeducation/hr-js-utils';
import { usePurchaserId } from '@openeducation/hr-rn-cognito-providers';
import {
  flex,
  HRHeading,
  HRLicensesList,
  HRChangeStudyPlanModal,
  HRActivitiesOverview,
  HRLevelProgress,
  HRLicenseHistory,
  HRCertificatesAndLevelTests,
  HRAssignReassignLicenseModal,
  useHRPortalTranslation,
  useAssignReassignLicense,
  useLevelProgress,
  useCertificates,
  useActivityCalendar,
  useDayActivity,
  useStudentLicensePreview,
  HRStudyPlanStudent,
  useStudyPlans,
  useStudentStudyPlanStatistics,
  useStudentStudyPlanProgress,
  useChangeStudyPlan,
  useCountrySelector,
  useUndoStudyPlan,
  pb,
  OEStatisticsOfActivities,
} from '@openeducation/hr-rn-components';
import {
  useGetAllActivitiesOverview,
  useGetStudentLicenseHistory,
  useGetLevelTests,
  useGetActivityIntervals,
} from '@openeducation/hr-rn-graphql-providers';
import {
  preferredLanguageAtom,
  studyPlanEnabledAtom,
} from '@openeducation/hr-rn-jotai-atoms';
import {
  LicenseStatus,
  StudentsListField,
  StudentsListItemData,
} from '@openeducation/hr-rn-shared-types';
import { useAtomValue } from 'jotai';
import React, { useCallback } from 'react';
import { View } from 'react-native';

import { useStudentsListProps } from '../../hooks/use-students-list-props';
import { OEPath } from '../../router';

const OEStudentScreen = () => {
  const { t } = useHRPortalTranslation();
  const studentsListProps = useStudentsListProps(OEPath.Student);
  const preferredLanguage = useAtomValue(preferredLanguageAtom);
  const studyPlanEnable = useAtomValue(studyPlanEnabledAtom);
  const purchaserId = usePurchaserId();
  const { id: contactId } = getUrlParams();
  const {
    studentLicense,
    isLoading: isLicenseLoading,
    refetchListData,
  } = useStudentLicensePreview(contactId);
  const personId = studentLicense?.personId;
  const {
    loading: activitiesOverviewLoading,
    data: activitiesOverviewData,
    isError: activitiesOverviewError,
  } = useGetAllActivitiesOverview({
    purchaserId,
    contactId,
  });
  const { data: licenseHistoryData, loading: isLicenseHistoryLoading } =
    useGetStudentLicenseHistory({ purchaserId, contactId });
  const studentName = studentLicense
    ? `${studentLicense?.firstName} ${studentLicense?.lastName}`
    : '';
  const { levelProgressData, isLevelProgressLoading } =
    useLevelProgress(personId);
  const hasValidLicense = isLicenseLoading || studentLicense;
  const { daySelected, ...calendarProps } = useActivityCalendar(personId);
  const { dayActivityProps } = useDayActivity({
    purchaserId,
    personId,
    date: daySelected,
  });
  const certificates = useCertificates(personId, contactId);
  const levelTests = useGetLevelTests({ purchaserId, contactId });
  useGetActivityIntervals({ purchaserId, contactId });
  const studyPlansProps = useStudyPlans(refetchListData);
  const unassignStudyPlanProps = useUndoStudyPlan(refetchListData);
  const { openChangeStudyPlan, modalChangeStudyPlanProps } = useChangeStudyPlan(
    studyPlansProps,
    unassignStudyPlanProps
  );
  const { openAssignReassign, modalAssignReassignLicenseProps } =
    useAssignReassignLicense(studyPlansProps, refetchListData);

  const countrySelectorProps = useCountrySelector();
  const studentProgressProps = useStudentStudyPlanProgress({
    purchaserId,
    contactId,
  });

  const studentStatiticsProps = useStudentStudyPlanStatistics({
    purchaserId,
    contactId,
  });

  const onChangeStudyPlan = useCallback(
    (license: StudentsListItemData) => {
      openChangeStudyPlan(license);
    },
    [openChangeStudyPlan]
  );

  const onAssignReassign = useCallback(
    (license: StudentsListItemData) => {
      openAssignReassign(license);
    },
    [openAssignReassign]
  );

  return (
    <>
      <HRHeading
        title={studentName}
        description={studentName && t('student.heading.description')}
        studentsListProps={studentsListProps}
      />
      {hasValidLicense && personId && (
        <>
          <HRLicensesList
            data={[studentLicense]}
            isLoading={isLicenseLoading}
            refetchListData={refetchListData}
            isLicensePreview
            onChangeStudyPlan={onChangeStudyPlan}
            onAssignReassign={onAssignReassign}
            fields={[
              StudentsListField.NAME,
              StudentsListField.EMAIL,
              StudentsListField.PRIVATE_CLASSES,
              StudentsListField.STATUS,
              StudentsListField.LAST_ACTIVITY,
              StudentsListField.EXPIRATION,
              studyPlanEnable
                ? StudentsListField.SETTINGS
                : StudentsListField.ASSIGN_REASSIGN,
            ]}
            style={[pb(0)]}
          />
          <HRAssignReassignLicenseModal
            {...modalAssignReassignLicenseProps}
            countrySelectorProps={countrySelectorProps}
          />
        </>
      )}
      {!activitiesOverviewError && (
        <HRActivitiesOverview
          isLoading={activitiesOverviewLoading}
          stats={activitiesOverviewData}
          subtitle={
            studentName &&
            t('student.activitiesOverview.subtitle', {
              name: studentName,
            })
          }
          isStudentView
        />
      )}
      {studyPlanEnable && (
        <>
          <HRStudyPlanStudent
            {...studentProgressProps}
            language={preferredLanguage}
            onChangePlanPress={() => {
              studentLicense && openChangeStudyPlan(studentLicense);
            }}
            {...studentStatiticsProps}
            studentName={studentName}
            disabled={
              !studentProgressProps?.studyPlanType ||
              !studentLicense?.status ||
              studentLicense?.status === LicenseStatus.NOT_ACTIVATED
            }
            disabledChangeStudyPlan={
              studentLicense?.status === LicenseStatus.IN_PROGRESS
            }
          />
          <HRChangeStudyPlanModal {...modalChangeStudyPlanProps} />
        </>
      )}

      <HRLevelProgress
        isLoading={isLevelProgressLoading}
        subtitle={
          studentName &&
          t('student.levelProgress.subtitle', {
            name: studentName,
          })
        }
        dayActivityProps={dayActivityProps}
        progressProps={levelProgressData}
        calendarProps={calendarProps}
      />
      <View style={[flex('flex-row', 'flex-1')]}>
        <OEStatisticsOfActivities contactId={contactId} />
        {hasValidLicense && (
          <HRLicenseHistory
            data={licenseHistoryData}
            activeLicense={studentLicense}
            isLoading={isLicenseHistoryLoading}
            onAssignReassign={onAssignReassign}
            refetchListData={refetchListData}
            language={preferredLanguage}
          />
        )}
      </View>
      <HRCertificatesAndLevelTests
        certificates={certificates}
        levelTests={levelTests}
      />
    </>
  );
};

export default OEStudentScreen;
