import { useHRPortalConfig } from '@openeducation/hr-rn-components';
import { hrPortalConfigAtom } from '@openeducation/hr-rn-jotai-atoms';
import { OrganizationType } from '@openeducation/hr-rn-shared-types';
import { useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';

import { OEPath, OMPath, useLocation } from '../router';

export const useSetHRPortalConfig = () => {
  const [organization, setOrganization] = useState<OrganizationType | null>(
    null
  );
  const hrPortalConfig = useHRPortalConfig(organization);
  const location = useLocation();
  const setHRPortalConfig = useSetAtom(hrPortalConfigAtom);
  const currentPath = location.pathname;
  const oePaths = Object.values(OEPath) as string[];
  const omPaths = Object.values(OMPath) as string[];

  useEffect(() => {
    let org = null;
    if (oePaths.includes(currentPath)) {
      org = OrganizationType.OPEN_ENGLISH;
    }

    if (omPaths.includes(currentPath)) {
      org = OrganizationType.OPEN_MUNDO;
    }
    setOrganization(org);
  }, [oePaths, omPaths, currentPath, setOrganization]);

  useEffect(() => {
    if (hrPortalConfig && organization) {
      setHRPortalConfig(hrPortalConfig);
    }
  }, [hrPortalConfig, setHRPortalConfig, organization]);
};
