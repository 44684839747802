import {
  HRPageTemplate,
  HRPageTemplateProps,
} from '@openeducation/hr-rn-components';
import { omLanguageSelected } from '@openeducation/hr-rn-jotai-atoms';
import { OrganizationType } from '@openeducation/hr-rn-shared-types';
import { useSetAtom } from 'jotai';
import React from 'react';

import { OEPath, Path, OMPath } from './paths';
import { useLocation, useNavigate } from './router';

const PageTemplate = ({
  children,
  ...props
}: Omit<HRPageTemplateProps, 'onSwitchPortal'> & {
  children: React.ReactNode;
}) => {
  const setLanguage = useSetAtom(omLanguageSelected);
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  const onSwitchPortal = (organization: OrganizationType) => {
    setLanguage(null);

    switch (organization) {
      case OrganizationType.OPEN_MUNDO:
        navigate(OMPath.Home);
        break;
      case OrganizationType.OPEN_ENGLISH:
      default:
        navigate(OEPath.Home);
        break;
    }
  };

  if (currentPath === Path.Choice) {
    return <>{children}</>;
  }
  return (
    <HRPageTemplate {...props} onSwitchPortal={onSwitchPortal}>
      {children}
    </HRPageTemplate>
  );
};

export { PageTemplate };
