import { usePurchaserId } from '@openeducation/hr-rn-cognito-providers';
import {
  SloganBanner,
  mb,
  HRModal,
  HRHeading,
  HRLicensesOverview,
  HRLicensesList,
  HRContactUsModal,
  HRChangeStudyPlanModal,
  HRAssignReassignLicenseModal,
  useHRPortalTranslation,
  useLicensesList,
  useAssignReassignLicense,
  useContactUs,
  useReport,
  useCountrySelector,
  useStudyPlans,
  useChangeStudyPlan,
  useUndoStudyPlan,
  saveFile,
} from '@openeducation/hr-rn-components';
import { useGetLicensesOverview } from '@openeducation/hr-rn-graphql-providers';
import {
  hrPortalConfigAtom,
  studyPlanEnabledAtom,
  omLanguageSelected,
} from '@openeducation/hr-rn-jotai-atoms';
import {
  StudentsListField,
  ReportType,
  StudentsListItemData,
} from '@openeducation/hr-rn-shared-types';
import { useAtomValue } from 'jotai';
import React, { useState, useEffect, useCallback } from 'react';
import { View } from 'react-native';

import { useStudentsListProps } from '../../hooks/use-students-list-props';
import { OMPath } from '../../router';

enum VisibleModal {
  CONTACT_US = 'CONTACT_US',
  CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS',
}

const OMLicensesScreen = () => {
  const [visibleModal, setVisibleModal] = useState<VisibleModal | null>(null);
  const { t } = useHRPortalTranslation();
  const purchaserId = usePurchaserId();

  const { moreLicensesEmailSubject, organization } =
    useAtomValue(hrPortalConfigAtom);

  const studyPlanEnable = useAtomValue(studyPlanEnabledAtom);
  const { langCode: languageCode = undefined } =
    useAtomValue(omLanguageSelected) || {};
  const { data: overviewData, loading: overviewLoading } =
    useGetLicensesOverview({
      purchaserId,
      organization,
      languageCode,
    });
  const contactUsProps = useContactUs({
    subject: moreLicensesEmailSubject,
  });
  const {
    data: listData,
    isLoading: listLoading,
    refetchListData,
  } = useLicensesList();
  const studentsListProps = useStudentsListProps(OMPath.Student);
  const licensesReport = useReport(saveFile, ReportType.LICENSES);
  const countrySelectorProps = useCountrySelector();

  const studyPlansProps = useStudyPlans(refetchListData);
  const unassignStudyPlanProps = useUndoStudyPlan(refetchListData);
  const { modalChangeStudyPlanProps } = useChangeStudyPlan(
    studyPlansProps,
    unassignStudyPlanProps
  );
  const { openAssignReassign, modalAssignReassignLicenseProps } =
    useAssignReassignLicense(studyPlansProps, refetchListData);

  useEffect(() => {
    if (contactUsProps?.isSuccess) {
      setVisibleModal(VisibleModal.CONTACT_US_SUCCESS);
    }
  }, [contactUsProps?.isSuccess]);

  const closeModals = () => setVisibleModal(null);

  const openContactModal = () => {
    setVisibleModal(VisibleModal.CONTACT_US);
  };

  const onAssignReassign = useCallback(
    (license: StudentsListItemData) => {
      openAssignReassign(license);
    },
    [openAssignReassign]
  );
  return (
    <>
      <HRHeading
        title={t('licenses.heading.title')}
        description={t('licenses.heading.description')}
        studentsListProps={studentsListProps}
        licensesReport={licensesReport}
        changeLanguageSelected
      />
      <HRLicensesOverview
        isLoading={overviewLoading}
        data={overviewData}
        onMoreLicensesPress={openContactModal}
      />
      <HRLicensesList
        data={listData}
        isLoading={listLoading}
        refetchListData={refetchListData}
        onAssignReassign={onAssignReassign}
        fields={[
          StudentsListField.NAME,
          StudentsListField.EMAIL,
          StudentsListField.PRIVATE_CLASSES,
          StudentsListField.STATUS,
          StudentsListField.EXPIRATION,
          StudentsListField.ASSIGN_REASSIGN,
        ]}
      />
      <HRAssignReassignLicenseModal
        {...modalAssignReassignLicenseProps}
        countrySelectorProps={countrySelectorProps}
      />

      {studyPlanEnable && (
        <HRChangeStudyPlanModal {...modalChangeStudyPlanProps} />
      )}
      <View style={[mb(40)]}>
        <SloganBanner
          text={t('licenses.slogan')}
          button={{
            onPress: openContactModal,
            text: t('licenses.overview.moreLicenses'),
          }}
        />
      </View>
      <HRContactUsModal
        title={t('getLicenses.title')}
        description={t('getLicenses.description')}
        visible={visibleModal === VisibleModal.CONTACT_US}
        onCancel={closeModals}
        isLoading={contactUsProps?.isLoading}
        error={contactUsProps?.error}
        onSubmit={contactUsProps?.onSubmit}
        managerName={contactUsProps?.managerName}
        managerEmail={contactUsProps?.managerEmail}
      />
      <HRModal
        visible={visibleModal === VisibleModal.CONTACT_US_SUCCESS}
        onClose={closeModals}
        successMessage={{ message: t('getLicenses.success.title') }}
        successMessageSecondary={{
          message: t('getLicenses.success.description'),
        }}
        autoDismiss
      />
    </>
  );
};

export default OMLicensesScreen;
