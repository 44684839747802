import { getUrlParams } from '@openeducation/hr-js-utils';
import { usePurchaserId } from '@openeducation/hr-rn-cognito-providers';
import {
  HRHeading,
  HRLicensesList,
  HRActivitiesOverview,
  HRLicenseHistory,
  HRAssignReassignLicenseModal,
  useHRPortalTranslation,
  useAssignReassignLicense,
  useStudentLicensePreview,
  useCountrySelector,
  OMLevelProgress,
  useOMLevelProgress,
  flex,
  OEStatisticsOfActivities,
} from '@openeducation/hr-rn-components';
import {
  useGetOMAllActivitiesOverview,
  useGetStudentLicenseHistory,
  useGetOMActivityIntervals,
  useGetOMLanguages,
} from '@openeducation/hr-rn-graphql-providers';
import { preferredLanguageAtom } from '@openeducation/hr-rn-jotai-atoms';
import {
  StudentsListField,
  StudentsListItemData,
} from '@openeducation/hr-rn-shared-types';
import { useAtomValue } from 'jotai';
import React, { useCallback } from 'react';
import { View } from 'react-native';

import { useStudentsListProps } from '../../hooks/use-students-list-props';
import { OMPath } from '../../router';

const OMStudentScreen = () => {
  const { t } = useHRPortalTranslation();
  const studentsListProps = useStudentsListProps(OMPath.Student);
  const preferredLanguage = useAtomValue(preferredLanguageAtom);
  const purchaserId = usePurchaserId();
  const { id: contactId } = getUrlParams();

  const { languages, isOMLanguageLoading } = useGetOMLanguages({
    purchaserId,
    contactId,
  });
  const languageCode = languages?.[0]?.langCode || '';

  const {
    studentLicense,
    isLoading: isLicenseLoading,
    refetchListData,
  } = useStudentLicensePreview(contactId);

  const {
    loading: activitiesOverviewLoading,
    data: activitiesOverviewData,
    isError: activitiesOverviewError,
  } = useGetOMAllActivitiesOverview({
    purchaserId,
    languageCode,
    contactId,
  });

  const { data: licenseHistoryData, loading: isLicenseHistoryLoading } =
    useGetStudentLicenseHistory({ purchaserId, contactId });
  const studentName = studentLicense
    ? `${studentLicense?.firstName} ${studentLicense?.lastName}`
    : '';

  const hasValidLicense = isLicenseLoading || studentLicense;

  const levelProgressProps = useOMLevelProgress(contactId, languageCode);

  useGetOMActivityIntervals({
    purchaserId,
    contactId,
    languageCode,
  });

  const { openAssignReassign, modalAssignReassignLicenseProps } =
    useAssignReassignLicense(undefined, refetchListData);

  const countrySelectorProps = useCountrySelector();

  const onAssignReassign = useCallback(
    (license: StudentsListItemData) => {
      openAssignReassign(license);
    },
    [openAssignReassign]
  );

  return (
    <>
      <HRHeading
        title={studentName}
        description={studentName && t('student.heading.description')}
        studentsListProps={studentsListProps}
        languages={languages}
        isLanguagesLoading={isOMLanguageLoading}
      />
      {hasValidLicense && (
        <>
          <HRLicensesList
            data={[{ ...studentLicense, language: languages?.[0] }]}
            isLoading={isLicenseLoading}
            refetchListData={refetchListData}
            isLicensePreview
            onAssignReassign={onAssignReassign}
            fields={[
              StudentsListField.NAME,
              StudentsListField.EMAIL,
              StudentsListField.PRIVATE_CLASSES,
              StudentsListField.STATUS,
              StudentsListField.EXPIRATION,
              StudentsListField.ASSIGN_REASSIGN,
            ]}
          />
          <HRAssignReassignLicenseModal
            {...modalAssignReassignLicenseProps}
            countrySelectorProps={countrySelectorProps}
          />
        </>
      )}
      {!activitiesOverviewError && (
        <HRActivitiesOverview
          isLoading={activitiesOverviewLoading}
          stats={activitiesOverviewData}
          subtitle={
            studentName &&
            t('student.activitiesOverview.subtitle', {
              name: studentName,
            })
          }
          isStudentView
          isOM
        />
      )}
      <OMLevelProgress {...levelProgressProps} />
      <View style={[flex('flex-row', 'flex-1')]}>
        <OEStatisticsOfActivities
          languageCode={languageCode}
          contactId={contactId}
        />
        {hasValidLicense && (
          <HRLicenseHistory
            data={licenseHistoryData}
            activeLicense={studentLicense}
            isLoading={isLicenseHistoryLoading}
            onAssignReassign={onAssignReassign}
            refetchListData={refetchListData}
            language={preferredLanguage}
          />
        )}
      </View>
    </>
  );
};

export default OMStudentScreen;
