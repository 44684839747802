import { getErrorTranslation } from '@openeducation/hr-rn-cognito-services';
import { HRResetPasswordPage } from '@openeducation/hr-rn-components';
import { useResetPassword } from '@openeducation/hr-rn-providers';
import type { CognitoAuthError } from '@openeducation/hr-rn-shared-types';
import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Path, useNavigate } from '../router';

const ResetPasswordScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    resetPassword,
    isResetPasswordError,
    resetPasswordError,
    isResetPasswordLoading,
    isResetPasswordSuccess,
  } = useResetPassword();
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (isResetPasswordSuccess && email) {
      navigate(Path.ResetPasswordEmail, { state: { email } });
    }
  }, [isResetPasswordSuccess, navigate, email]);

  const handleSubmit = useCallback(
    (formEmail: string) => {
      setEmail(formEmail);
      return resetPassword({ email: formEmail });
    },
    [setEmail, resetPassword]
  );

  return (
    <HRResetPasswordPage
      onSubmit={handleSubmit}
      isLoading={isResetPasswordLoading}
      error={
        isResetPasswordError
          ? t(getErrorTranslation(resetPasswordError as CognitoAuthError))
          : ''
      }
    />
  );
};

export default ResetPasswordScreen;
