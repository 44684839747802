import { ApolloProvider } from '@apollo/client';
import { hostsConfig } from '@openeducation/hr-js-utils';
import { cognitoUserTokensAtom } from '@openeducation/hr-rn-cognito-providers';
import { flex, CenteredLoading } from '@openeducation/hr-rn-components';
import { hrPortalApolloClient } from '@openeducation/hr-rn-graphql-services';
import {
  preferredLanguageAtom,
  hrPortalConfigAtom,
} from '@openeducation/hr-rn-jotai-atoms';
import {
  EnvironmentProvider,
  LocalizationProvider,
  ReactQueryInitProvider,
} from '@openeducation/hr-rn-providers';
import {
  Environment,
  LanguageDirection,
} from '@openeducation/hr-rn-shared-types';
import { HRTheme, ThemeProvider } from '@openeducation/hr-rn-themes';
import { useAtomValue } from 'jotai';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { QueryClient } from 'react-query';

interface AppProvidersProps {
  env: Environment;
  children?: React.ReactNode;
}

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

export const AppProviders: React.FC<AppProvidersProps> = ({
  children,
  env,
}) => {
  const localizationEndpoint = `${hostsConfig[env].thirdpartyService}/v1/translations/\${lng}?projectId=387920`;
  const preferredLanguage = useAtomValue(preferredLanguageAtom);
  const tokens = useAtomValue(cognitoUserTokensAtom);
  const { i18n } = useTranslation();

  return (
    <EnvironmentProvider env={env}>
      <ReactQueryInitProvider queryClient={queryClient}>
        <LocalizationProvider
          environment={env}
          loadingComponent={
            <CenteredLoading style={flex('flex-1')} size={100} />
          }
          endpoint={localizationEndpoint}
          language={preferredLanguage}
        >
          <ApolloProvider
            client={hrPortalApolloClient(env, tokens?.accessToken || '')}
          >
            <ThemeProvider
              theme={HRTheme}
              direction={i18n.dir(preferredLanguage) as LanguageDirection}
            >
              {children}
            </ThemeProvider>
          </ApolloProvider>
        </LocalizationProvider>
      </ReactQueryInitProvider>
    </EnvironmentProvider>
  );
};
