import {
  HRHeading,
  HRAdminList,
  useAdminUsers,
  HRAdminUserChangeStatusModal,
  HRAdminUserResetPasswordModal,
  HRAdminUserCreateUserModal,
  HRAdminUserFeedbackModal,
} from '@openeducation/hr-rn-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
const OEAdminScreen = () => {
  const { t } = useTranslation();
  const adminUsersProps = useAdminUsers();

  return (
    <>
      <HRHeading title={t('hr2.header.admin')} />
      <HRAdminList
        data={adminUsersProps.adminUsersData}
        isLoading={adminUsersProps.adminUsersLoading}
        onChangeStatus={adminUsersProps.onAdminUserChangeStatusOpen}
        onPasswordReset={adminUsersProps.onAdminUserResetOpen}
      />
      <HRAdminUserResetPasswordModal
        isVisible={adminUsersProps.isResetModalVisible}
        onClose={adminUsersProps.onAdminUserResetClose}
        user={adminUsersProps.resetItem}
        isLoading={adminUsersProps.adminUserResetAccountLoading}
        onSubmit={adminUsersProps.onAdminUserResetSubmit}
      />
      <HRAdminUserCreateUserModal
        isVisible={adminUsersProps.isCreateModalVisible}
        onClose={adminUsersProps.onAdminUserResetClose}
        user={adminUsersProps.resetItem}
        isLoading={adminUsersProps.adminUserResetAccountLoading}
        onSubmit={adminUsersProps.onAdminUserResetSubmit}
      />
      <HRAdminUserChangeStatusModal
        isVisible={adminUsersProps.isChangeStatusModalVisible}
        onClose={adminUsersProps.onAdminUserChangeStatusClose}
        user={adminUsersProps.changeStatusItem}
        isLoading={adminUsersProps.adminUserStatusLoading}
        onSubmit={adminUsersProps.onAdminUserChangeStatusSubmit}
      />
      <HRAdminUserFeedbackModal
        isVisible={adminUsersProps.isFeedbackModalVisible}
        onClose={adminUsersProps.onAdminUserFeedbackModalClose}
        message={adminUsersProps.feedbackMessage}
        hasError={adminUsersProps.hasError}
      />
    </>
  );
};

export default OEAdminScreen;
