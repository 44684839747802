import { useIsAdmin } from '@openeducation/hr-rn-cognito-providers';
import type { NavItemProps } from '@openeducation/hr-rn-components';
import { hrPortalConfigAtom } from '@openeducation/hr-rn-jotai-atoms';
import { OrganizationType } from '@openeducation/hr-rn-shared-types';
import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Path, useNavigate, useLocation, PathType } from '../router';
import { OE_NAV_ITEMS, OM_NAV_ITEMS } from '../router/nav-items';
import type { NavItemsProps } from '../router/nav-items';

export type NavItemType = {
  path: PathType;
  labelKey: string;
  isAdmin: boolean;
};

export const useNavItems = () => {
  const { organization } = useAtomValue(hrPortalConfigAtom);
  const isAdmin = useIsAdmin();
  const navItems = useMemo(() => {
    switch (organization) {
      case OrganizationType.OPEN_ENGLISH:
        return OE_NAV_ITEMS;
      case OrganizationType.OPEN_MUNDO:
        return OM_NAV_ITEMS;
      default:
        return [];
    }
  }, [organization]);

  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const { t } = useTranslation();

  const goTo = useCallback(
    (path: Path) => () => {
      navigate(path);
    },
    [navigate]
  );

  const navItemFactory = (navItem: NavItemType): NavItemProps => ({
    isActive: currentPath === navItem.path,
    onPress: goTo(navItem.path),
    label: t(navItem.labelKey),
  });

  const items: NavItemProps[] = (navItems || [])
    .filter((item: NavItemsProps) => !item.isAdmin || (item.isAdmin && isAdmin))
    .map((navItem: NavItemsProps) => navItemFactory(navItem as NavItemType));

  return items;
};
