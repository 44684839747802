import { OEPath, OMPath } from './paths';

export interface NavItemsProps {
  path: string;
  labelKey: string;
  isAdmin?: boolean;
}

export const OE_NAV_ITEMS = [
  {
    path: OEPath.Home,
    labelKey: 'hr2.header.home',
  },
  {
    path: OEPath.Licenses,
    labelKey: 'hr2.header.licenses',
  },
  {
    path: OEPath.Admin,
    labelKey: 'hr2.header.admin',
    isAdmin: true,
  },
] as NavItemsProps[];

export const OM_NAV_ITEMS = [
  {
    path: OMPath.Home,
    labelKey: 'hr2.header.home',
  },
  {
    path: OMPath.Licenses,
    labelKey: 'hr2.header.licenses',
  },
  {
    path: OMPath.Admin,
    labelKey: 'hr2nu.header.admin',
    isAdmin: true,
  },
] as NavItemsProps[];
