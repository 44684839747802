import { useEffect, useMemo } from 'react';

import { Path, useNavigate, useLocation } from '../router';
import { privateRoutes } from '../router/private-routes';
import { publicRoutes } from '../router/public-routes';

export const useResolveInitialRoute = (isLoggedIn: boolean | null) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const isPublicPath = useMemo(
    () => publicRoutes.find((route) => route.path === currentPath),
    [currentPath]
  );
  const isPrivatePath = useMemo(
    () => privateRoutes.find((route) => route.path === currentPath),
    [currentPath]
  );
  const is404 = !isPublicPath && !isPrivatePath;

  useEffect(() => {
    if (isLoggedIn === null) return;
    if (isLoggedIn && isPublicPath) {
      navigate(Path.Root);
      return;
    }
    if (!isLoggedIn && (isPrivatePath || is404)) {
      navigate(Path.Login);
    }
  }, [isLoggedIn, isPublicPath, isPrivatePath, navigate, is404]);
};
